
/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + "";
  const randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns {Array}
 */
export function diffArary(arr1, arr2) {
  arr1 = uniqueArr(arr1);
  arr2 = uniqueArr(arr2);
  return arr1
    .concat(arr2)
    .filter((arg) => !(arr1.includes(arg) && arr2.includes(arg)));
}

/**
 * 网店类型
 * 【0-普通用户  1-小网店  2-大网店  4-分公司】
 * @param value
 * @returns {*}
 */
export const handleShareState = (value) => {
  var list = {
    '0': { 'title': '普通用户', 'color': '' },
    '1': { 'title': '形象店', 'color': 'info' },
    '2': { 'title': '旗舰店', 'color': 'success' },
    '3': { 'title': '旗舰店', 'color': 'primary' },
    '4': { 'title': '运营中心', 'color': 'warning' },
    '5': { 'title': '小网店', 'color': 'danger' }
  }

  // console.log(list[value.toString()])

  return value ? list[value.toString()] : list['0']
}
