<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>共享商城</el-breadcrumb-item>
                    <el-breadcrumb-item>用户列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="关键字">
                            <el-input
                                    v-model="where.account"
                                    placeholder="请输入账号"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="where.shareMallStatus" placeholder="请选择">
                                <el-option label="全部" value="0"></el-option>
                                <el-option label="正常" value="1"></el-option>
                                <el-option label="过期" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="区域">
                            <operation-area
                                    v-model="operationArea"
                                    @get-operation-area="getOperationArea"
                                    :show="false"
                                    :data="operationArea"
                            ></operation-area>
                        </el-form-item>
                        <el-form-item label="申请时间">
                            <el-date-picker
                                    v-model="date"
                                    @change="orderDate" @input="immediUpdate"
                                    type="monthrange"
                                    range-separator="~"
                                    clearable
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始月份"
                                    end-placeholder="结束月份"
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="getList()">条件筛选</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="info" plain icon="el-icon-delete" @click="reset()">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="$router.push({ name: 'shareMallUserAdd' })"
                                    icon="el-icon-plus"
                            >绑定角色
                            </el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="success"
                                    icon="el-icon-delete"
                                    @click="$router.push({ name: 'shareMallUserBinding' })"
                            >绑定关系
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.3)"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="parAccount" label="上级账号"></el-table-column>
                    <el-table-column prop="account" label="账号"></el-table-column>
                    <el-table-column prop="nickName" label="昵称"></el-table-column>
                    <el-table-column prop="shareMallName" label="商城名称"></el-table-column>
                    <el-table-column prop="shareMallLevelTxt" label="角色">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.shareMallLevel"
                                       :type="handleShareState(scope.row.shareMallLevel.toString()) && handleShareState(scope.row.shareMallLevel.toString()).color" size="mini"
                                       disabled>{{ scope.row.shareMallLevelTxt }}
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="statusTxt" label="状态">
                        <template slot-scope="scope">
                            <el-link v-if="scope.row.statusTxt"
                                     :type="handleShareState(scope.row.status.toString()).color" size="mini" disabled>{{
                                scope.row.statusTxt }}
                            </el-link>
                        </template>
                    </el-table-column>

                    <el-table-column prop="shareMallPayTime" label="支付时间"></el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                    type="danger"
                                    icon="el-icon-delete"
                                    @click="del(scope.row.id)"
                            >解绑角色
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>

                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {handleShareState} from '@/utils/index';
    import operationArea from "@/components/admin/operationArea.vue";

    export default {
        components: {operationArea},
        data() {
            return {
                list: [],
                selectId: "",
                loading: false,
                operationArea: '',
                infoDetail: {},
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 1,
                goods_verify_count: 0,
                date:'',
                where: {
                    pageSize: 0,
                    pageNum: 1,
                    account: "",
                    startDt: '',
                    endDt: '',
                    province: '',
                    city: '',
                    area: '',
                    shareMallStatus: '0',
                },
            };
        },
        methods: {
            handleShareState,
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            getList() {
                this.loading = true;
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.shareMall.list, this.where).then((res) => {
                    if (res.code === 1) {
                        this.totalData = res.data.total;
                        this.list = res.data.records;
                        console.log(1111111)
                    }
                    this.loading = false;
                });
            },
            // 删除处理
            del(id) {
                if (this.$isEmpty(id)) {
                    return this.$message.error("请先选择解绑的对象");
                }
                this.$confirm("是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$post(this.$api.shareMall.delete, {id: id}).then(
                            (res) => {
                                if (res.code === 1) {
                                    this.getList();
                                    this.$router.go(0);
                                    this.$message.success("解绑成功");
                                } else {
                                    this.$message.error("解绑失败");
                                }
                            }
                        );
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },
            currentChange(e) {
                this.currentPage = e;
                this.getList();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.getList();
            },
            orderDate(obj) {
                if (obj) {
                    this.where.startTime = obj[0];
                    this.where.endTime = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if (e == null) {
                        this.where.startTime = '';
                        this.where.endTime = '';
                    }
                });
            },
            exportData() {
                // console.log("exportData");
            },
            getOperationArea(data) {
                const val = data["code"];
                const name = data["name"];
                console.log(val, name)
                if (val && val.length > 0 && Array.isArray(val)) {
                    const end = val.slice(-1);
                    this.where.province = val[0];
                    this.where.city = val[1];
                    this.where.area = val[2];
                    this.operationAreaCode = end[0];
                    this.operationArea = name.join("/");
                }
            },
            reset(){
                this.operationArea = this.operationAreaCode = this.date = ''
                this.where =  {
                    pageSize: 0,
                        pageNum: 1,
                        account: "",
                        startDt: '',
                        endDt: '',
                        province: '',
                        city: '',
                        area: '',
                        shareMallStatus: '0',
                }
            }
        },
        created() {
            this.getList();
        },
    };
</script>
<style lang="scss" scoped>
    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }
</style>
